import { ref, computed } from "vue";
import { defineStore } from "pinia";

import { ScreenState, ComponentPropsInModal, LoadingStatePerApis } from "@/stores/models";
import { CommonAccountsEnums } from "@/apis/enums";

export const useScreenStateStore = defineStore("screenState", () => {
    const screenState = ref<ScreenState<keyof ComponentPropsInModal>>({
        modalOpen: false,
        modalComponent: null,
        modalProps: null,
        isLoading: false,
        pageTitle: "",
        stepIndex: 0,
    });

    const loadingStatePerApis = ref<LoadingStatePerApis>({
        requestUserPhoneNumberLoading: false,
        requestUserVerificationLoading: false,
        requestUserEmailDuplicateLoading: false,
        requestSearchSchoolLoading: false,
        requestTermsLoading: false,
        requestSubmitUserInfomationLoading: false,
    });

    /** setLoading */
    const setupLoadingState = (apiName: keyof LoadingStatePerApis) => {
        Object.keys(loadingStatePerApis.value).forEach((key) => {
            if (key === apiName) loadingStatePerApis.value[key] = true;
            else loadingStatePerApis.value[key] = false;
        });
    };

    const requestUserPhoneNumberLoading = computed(() => {
        return loadingStatePerApis.value.requestUserPhoneNumberLoading
            ? screenState.value.isLoading
            : false;
    });
    const requestUserVerificationLoading = computed(() => {
        return loadingStatePerApis.value.requestUserVerificationLoading
            ? screenState.value.isLoading
            : false;
    });
    const requestUserEmailDuplicateLoading = computed(() => {
        return loadingStatePerApis.value.requestUserEmailDuplicateLoading
            ? screenState.value.isLoading
            : false;
    });
    const requestSearchSchoolLoading = computed(() => {
        return loadingStatePerApis.value.requestSearchSchoolLoading
            ? screenState.value.isLoading
            : false;
    });
    const requestTermsLoading = computed(() => {
        return loadingStatePerApis.value.requestTermsLoading ? screenState.value.isLoading : false;
    });
    const requestSubmitUserInfomationLoading = computed(() => {
        return loadingStatePerApis.value.requestSubmitUserInfomationLoading
            ? screenState.value.isLoading
            : false;
    });

    /** stepIndex */
    const setStepIndex = (stepIndex: number) => {
        screenState.value.stepIndex = stepIndex;
    };

    const nextStepIndex = () => {
        screenState.value.stepIndex++;
    };

    const backStepIndex = () => {
        screenState.value.stepIndex > 0 && screenState.value.stepIndex--;
    };

    /** loading state */
    const requestLoading = () => {
        screenState.value.isLoading = true;
    };

    const responseLoading = () => {
        screenState.value.isLoading = false;
    };

    /** pageTitle */

    const addServiceTagToTitle = (pageTitle: string, service: string) => {
        switch (service) {
            case CommonAccountsEnums.ServiceType.Red:
                return `RED ${pageTitle}`;
            case CommonAccountsEnums.ServiceType.Blue:
                return `BLUE ${pageTitle}`;
            default:
                return pageTitle;
        }
    };

    const setPageTitle = (pageTitle: string) => {
        screenState.value.pageTitle = pageTitle;
        return screenState.value.pageTitle;
    };

    const setPageTitleWidthService = (pageTitle: string, service: string) => {
        screenState.value.pageTitle = addServiceTagToTitle(pageTitle, service);
        return screenState.value.pageTitle;
    };

    /** modal event */
    const onmountModal = <T extends keyof ComponentPropsInModal>(
        component: T,
        props: ComponentPropsInModal[T],
    ) => {
        document.body.style.overflow = "hidden";
        screenState.value.modalComponent = component;
        screenState.value.modalProps = props ? { ...props } : null;
        screenState.value.modalOpen = true;
    };

    const unmountModal = () => {
        document.body.style.overflow = "scroll";
        screenState.value.modalOpen = false;
        screenState.value.modalComponent = null;
        screenState.value.modalProps = null;
    };

    return {
        screenState,
        loadingStatePerApis,
        setupLoadingState,
        setPageTitle,
        setPageTitleWidthService,
        setStepIndex,
        nextStepIndex,
        backStepIndex,
        onmountModal,
        unmountModal,
        requestLoading,
        responseLoading,
        requestUserPhoneNumberLoading,
        requestUserVerificationLoading,
        requestUserEmailDuplicateLoading,
        requestSearchSchoolLoading,
        requestTermsLoading,
        requestSubmitUserInfomationLoading,
    };
});
