import { onUnmounted, ref, onMounted } from "vue";
import { useResponseDataStore } from "@/stores/responseData";
import { useScreenStateStore } from "@/stores/screenState";

import type { PostMessageFormat } from "@/models/composables/models";

const isTouch = ref<boolean>(false);

const messageEventHandlers =
    (responseDataStore: ReturnType<typeof useResponseDataStore>) => (event: Event) => {
        event.preventDefault();
        const userDto = responseDataStore.responseData.userDto;
        const messageObj: PostMessageFormat = { event: "popup-close", userDto: userDto };

        window.opener.postMessage(JSON.stringify(messageObj), document.referrer);

        window.removeEventListener("pagehide", messageEventHandlers(responseDataStore));
    };

const popStateHandlers = (screenStateStore: ReturnType<typeof useScreenStateStore>) => () => {
    // 1. 첫 렌더링 때는 isTouch.value 가 false 이기에 실행되지 않습니다.
    // 2. 사용자가 모바일을 touch 한 경우, isTouch.value 가 true 가 되며 팝업창이 열립니다.
    if (window.history.state.popup === "true" && isTouch.value) {
        screenStateStore.onmountModal("AlertMobileWindowBeforeUnloadTemplate", {
            title: "과정을 중단하고 나가시겠습니까?",
        });
    }
};

const touchEventHandlers = (eventString: "click" | "touchend") => () => {
    if (isTouch.value) return; // 다만 window 특성상 click 이벤트는 자체적으로 가지고 있어서 위에 isTouch.value 가 true 이면 return 하도록 합니다.

    // 사용자가 모바일을 touch 할 시 history 에 popup: false 가 추가되며(stack), isTouch 는 true 로 변경됩니다.
    window.history.pushState({ popup: "false" }, "", window.location.href);
    isTouch.value = true;

    // 이후 바로 TocuEvnet 를 제거합니다.
    window.removeEventListener(eventString, touchEventHandlers(eventString));
};

/** 모바일과 데스크탑 내 event 를 설정 */
const determineEvent = () => {
    if ("ontouchend" in document.documentElement === true) {
        return "touchend";
    } else {
        return "click";
    }
};

const useListenPopupEvents = () => {
    const responseDataStore = useResponseDataStore();
    const screenStateStore = useScreenStateStore();

    const event = determineEvent();

    window.addEventListener(event, touchEventHandlers(event));
    window.history.pushState({ popup: "true" }, "", window.location.href);

    onMounted(() => {
        window.addEventListener("popstate", popStateHandlers(screenStateStore));
        window.addEventListener("pagehide", messageEventHandlers(responseDataStore));
    });

    onUnmounted(() => {
        window.removeEventListener("pagehide", messageEventHandlers(responseDataStore));
        window.removeEventListener("popstate", popStateHandlers(screenStateStore));
    });
};

export default useListenPopupEvents;
