<script setup lang="ts">
import { computed } from "vue";

const modalSize = {
    sm: "30rem",
    md: "65rem",
    lg: "80rem",
    xl: "120rem",
};

const props = defineProps<{
    size: keyof typeof modalSize;
}>();

const customClass = computed(() => {
    return {
        "max-w-[30rem] sm:max-w-full": props.size === "sm",
        "max-w-[50rem] sm:max-w-full": props.size === "md",
        "max-w-[80rem] sm:max-w-full": props.size === "lg",
        "max-w-[120rem] sm:max-w-full": props.size === "xl",
    };
});
</script>

<template>
    <div
        class="w-full h-auto max-w-[120rem] max-h-[80rem] p-[6rem] bg-white rounded-20 text-14 overflow-y-auto sm:p-[3rem] sm:h-full sm:rounded-0 sm:max-h-full"
        :class="customClass"
    >
        <div class="w-full h-auto max-h-[80rem] overflow-y-auto sm:max-h-full sm:h-full">
            <slot></slot>
        </div>
    </div>
</template>
