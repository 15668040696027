<script setup lang="ts">
import { ref, defineAsyncComponent, computed } from "vue";
import { useScreenStateStore } from "@/stores/screenState";

const screenStateStore = useScreenStateStore();
const { screenState, unmountModal } = screenStateStore;

type ModalComponent = typeof screenState.modalComponent | null;

const modalComponent = ref<ModalComponent>(null);
modalComponent.value = screenState.modalComponent;

const component = computed(() => {
    const modalComponentPath = screenState.modalComponent;
    return defineAsyncComponent(
        () => import(`@/components/templates/modals/${modalComponentPath}.vue`),
    );
});

const customWithClass = computed(() => {
    return {
        "sm:w-full": !screenState.modalComponent?.match(/Alert/i),
    };
});
</script>

<template>
    <Teleport to="body" v-if="screenState.modalOpen">
        <div
            class="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black z-999 bg-opacity-30"
            @click.self="unmountModal"
        >
            <div
                class="flex items-center justify-center h-auto w-fit z-1000 sm:h-full"
                :class="customWithClass"
                tabindex="-1"
            >
                <Transition name="fade">
                    <component :is="component" />
                </Transition>
            </div>
        </div>
    </Teleport>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);

    opacity: 0;
}
</style>
