<script setup lang="ts">
import useListenPopupEvents from "./composables/useListenPopupEvents";

import MainLayout from "./layouts/MainLayout.vue";
import CommonModalTemplate from "./components/templates/CommonModalTemplate.vue";
import ModalLoadingTemplate from "@/components/templates/modals/ModalLoadingTemplate.vue";
import RootErrorBoundary from "./components/templates/errors/RootErrorBoundary.vue";

useListenPopupEvents();
console.log(window.history);
</script>

<template>
    <RootErrorBoundary>
        <MainLayout>
            <router-view></router-view>
        </MainLayout>
        <Suspense>
            <CommonModalTemplate />
            <template #fallback>
                <ModalLoadingTemplate />
            </template>
        </Suspense>
    </RootErrorBoundary>
</template>

<style scoped></style>
