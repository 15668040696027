<script setup lang="ts">
import { useScreenStateStore } from "@/stores/screenState";

const screenStateStore = useScreenStateStore();
</script>

<template>
    <section class="flex flex-col items-center justify-center h-auto mb-[12rem] w-fit sm:mb-[4rem]">
        <div class="flex flex-col items-center justify-center w-full h-auto gap-[30px]">
            <img src="/assets/imgs/logo_susimdal.svg" alt="logo" class="h-[4.5rem] w-[15.8rem]" />
            <h1 class="font-pretended-600 text-36 text-custom-black sm:hidden">
                {{ screenStateStore.screenState.pageTitle }}
            </h1>
        </div>
        <div></div>
    </section>
</template>

<style scoped></style>
