import { ref } from "vue";
import { defineStore } from "pinia";

import type { ResponseData } from "@/stores/models";
import { CommonAccountsDtos } from "@/apis/dtos";

export const useResponseDataStore = defineStore("responseDataStore", () => {
    const responseData = ref<ResponseData>({
        userDto: null,
    });

    /** set */

    const setUserDtoToResponseDataStore = (
        userDto: CommonAccountsDtos.UserDto | CommonAccountsDtos.UserDtoV1,
    ) => {
        responseData.value.userDto = userDto;
    };

    return { responseData, setUserDtoToResponseDataStore };
});
