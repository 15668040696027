import { createI18n } from "vue-i18n";
import messages from "@/locales/index";

const i18n = createI18n({
    legacy: false,
    locale: "ko", // 기본언어
    fallbackLocale: "ko", // 지정한 언어에 번역이 없을 때 보여줄 언어 (한국어)
    messages: messages,
    silentTranslationWarn: true,
});

export default i18n;
