export namespace CommonAccountsEnums {
    export enum TokenType {
        AccessToken = "access-token",
        RefreshToken = "refresh-token",
    }

    export enum GenderType {
        Male = 0,
        Female = 1,
    }

    export enum GradeType {
        ElemantarySchool1 = 1, // 초등학교
        ElemantarySchool2 = 2,
        ElemantarySchool3 = 3,
        ElemantarySchool4 = 4,
        ElemantarySchool5 = 5,
        ElemantarySchool6 = 6,
        MiddleSchool1 = 7, // 중학교
        MiddleSchool2 = 8,
        MiddleSchool3 = 9,
        HighSchool1 = 10, // 고등학교
        HighSchool2 = 11,
        HighSchool3 = 12,
    }
    export enum UserType {
        Student = 0,
        Teacher = 1,
        PrincipalTeacher = 2,
    }

    export enum ServiceType {
        Blue = "65b9a45397ac0837a4e7f662",
        Red = "65b9a45397ac0837a4e7f661",
    }

    export enum SchoolType {
        Elementary = 0,
        Middle = 1,
        High = 2,
    }
}
