import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
    history: createWebHistory(import.meta.env.VITE_BASE_URL),
    routes: [
        {
            path: "/migrate",
            name: "migrate",
            component: () => import(`@/views/CommonAccountMigration.vue`),
        },
        {
            path: "/sign-up",
            name: "signup",
            component: () => import(`@/views/CommonAccountSignUp.vue`),
        },
        {
            path: "/update-term",
            name: "update-term",
            component: () => import(`@/views/CommonAccountUpdateTerm.vue`),
        },
        {
            path: "/edit",
            name: "edit",
            component: () => import(`@/views/CommonAccountEdit.vue`),
        },
        {
            path: "/find",
            name: "find",
            component: () => import(`@/views/CommonAccountFind.vue`),
        },
        {
            path: "/connect-service",
            name: "connect-service",
            component: () => import(`@/views/CommonAccountConnectService.vue`),
        },
        {
            path: "/sign-up-watermelon",
            name: "signup-watermelon",
            component: () => import(`@/views/temporary/CommonAccountsTemporarySignUp.vue`),
        },
        {
            path: "/edit-watermelon",
            name: "edit-watermelon",
            component: () => import(`@/views/temporary/CommonAccountsTemporaryEdit.vue`),
        },
    ],
});

export default router;
