<script setup lang="ts">
import { onErrorCaptured } from "vue";
import { isAxiosError } from "axios";

import { useScreenStateStore } from "@/stores/screenState";

import { CommonAccountsErrorResponse } from "@/apis/models";

const screenStateStore = useScreenStateStore();

/** root error boundary */
onErrorCaptured((error) => {
    /** 의도치 않은 에러에 한하여 throw */
    if (!isAxiosError<CommonAccountsErrorResponse>(error)) {
        screenStateStore.onmountModal("AlertUnExpectedErrorModalTemplate", {
            title: `관리자에게 문의해주세요.`,
            message: error.message,
        });
        return true;
    }
    const errorResponse = error.response?.data;

    /** code 가 지정되어있지 않는 에러에 한하여 throw */
    if (!errorResponse?.errorCode) {
        screenStateStore.onmountModal("AlertUnExpectedErrorModalTemplate", {
            title: `관리자에게 문의해주세요.`,
            message: error.message,
        });
        return true;
    }

    /** tokken error 에 관한 코드는 throw, 나머지 에러는 알림창 */
    switch (errorResponse.errorCode) {
        case "COMMON-ACCOUNTS-004":
            return true;
        case "COMMON-ACCOUNTS-005":
            return true;
        default:
            screenStateStore.onmountModal("AlertUnExpectedErrorModalTemplate", {
                title: `관리자에게 문의해주세요.`,
                message: `${errorResponse.errorCode}\n${errorResponse.errorMessage}`,
            });
            return false;
    }
});
</script>

<template>
    <slot></slot>
</template>

<style scoped></style>
